import styled from 'styled-components';
export const DescriptionText = styled.p`
  margin-bottom: 175px;
  max-width: 708px;
  width: 100%;

  > pre {
    background: #f9f9f9;
    margin-top: 24px;
    padding: 16px;
  }
`;
export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: inherit;
`;
export const Section = styled.section`
  margin-bottom: 48px;
`;
export const SmallHeading = styled.h5`
  font-size: 16px;
  font-weight: bold;
  margin: 0;
`;
export const Header = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin: 14px 0;
`;
export const Title = styled.h1`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 32px;
`;
export const AttachmentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;