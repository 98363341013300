import { WarningIcon } from 'react-line-awesome';
import styles from './styles.module.scss';
const EmptyFilePill = ({
  linkText
}) => {
  return <div className={`${styles.pill} ${styles.emptyPill}`}>
      <div className={`${styles.pillLink} ${styles.emptyPillLink}`}>
        <WarningIcon style={{
        fontSize: '32px',
        marginRight: '4px'
      }} />
        <div className={styles.pillText}>
          <p>{linkText}</p>
        </div>
      </div>
    </div>;
};
export default EmptyFilePill;