import React, { useEffect, useState } from 'react';
import { Select, TYPE } from 'baseui/select';
const OrganizationSelect = ({
  value,
  changeOrganization,
  organizations
}) => {
  const [orgOptions, setOrgOptions] = useState([]);
  useEffect(() => {
    if (organizations && organizations.length > 0) {
      setOrgOptions(organizations);
    }
  }, [organizations]);
  return <div data-testid="organization-select">
      <Select options={orgOptions} onChange={params => {
      changeOrganization(params.value.length > 0 ? Number(params.value[0]['id']) : null);
    }} type={TYPE.search} value={value ? orgOptions.filter(org => org.id === value) : []} placeholder="Select Client" labelKey="name" isLoading={!orgOptions || orgOptions.length === 0}></Select>
    </div>;
};
const OrganizationSelectMulti = ({
  value,
  changeOrganization,
  organizations
}) => {
  const [orgOptions, setOrgOptions] = useState([]);
  useEffect(() => {
    if (organizations && organizations.length > 0) {
      setOrgOptions(organizations);
    }
  }, [organizations]);
  return <div data-testid="organization-select-multi">
      <Select options={orgOptions} onChange={params => {
      changeOrganization(params.value.length > 0 ? params.value.map(org => Number(org.id)) : []);
    }} type={TYPE.search} multi value={value.length > 0 ? orgOptions.filter(org => value.includes(org.id)) : []} placeholder="Select Client" labelKey="name" isLoading={!orgOptions || orgOptions.length === 0}></Select>
    </div>;
};
export { OrganizationSelect, OrganizationSelectMulti };