import { Card, StyledTitle } from 'baseui/card';
import * as S from './styles';
const ScopeCard = ({
  scope
}) => {
  return <Card data-testid="scope-card" overrides={{
    Root: {
      style: {
        borderWidth: '1px',
        marginTop: '32px',
        marginBottom: '32px',
        padding: '14px'
      }
    },
    Body: {
      style: {
        fontSize: '14px'
      }
    }
  }}>
      <StyledTitle>{scope.name}</StyledTitle>
      {scope.description.length > 0 ? <S.ScopeContent dangerouslySetInnerHTML={{
      __html: scope.description
    }}></S.ScopeContent> : <p>No description</p>}
    </Card>;
};
export const ScopeList = ({
  scopes
}) => {
  return <div data-testid="scope-list">
      <S.Header>Scope Items</S.Header>
      {scopes.map(scope => <ScopeCard key={scope.id} scope={scope} />)}
    </div>;
};
export default ScopeCard;