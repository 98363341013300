import styled from 'styled-components';
export const Header = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin: 14px 0;
`;
export const ScopeContent = styled.div`
  a {
    color: #4d7cfe;
  }
`;