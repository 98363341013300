import styled from 'styled-components';
export const Header = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin: 14px 0;

  > span {
    color: #98a9bc;
    margin-left: 4px;
  }
`;
export const Tags = styled.div`
  > span {
    color: #98a9bc;
  }
`;
export const TagContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: 8px;
  gap: 8px;
`;
export const TagItem = styled.span`
  background: #f1f2f4;
  border: 1px sold #e8ecef;
  border-radius: 4px;
  color: #44546f;
  font-size: 14px;
  padding: 4px 8px;
  width: auto;
`;
export const SubjectTagLine = styled.div`
  color: #626f86;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  margin-bottom: 14px;

  span {
    :not(:first-child) {
      margin-left: 8px;
      &:before {
        content: '·';
        margin-right: 8px;
      }
    }
  }
`;
export const Address = styled.div`
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: [icon] 20px [text] auto;
  margin-bottom: 14px;

  > .la {
    align-self: center;
    color: #111 !important;
    font-size: 20px;
    grid-column-start: icon;
  }

  p {
    align-self: center;
    grid-column-start: text;
    margin-bottom: 0;
  }
`;
export const SubjectTitle = styled.h1`
  font-size: 24px;
  margin-bottom: 8px;
`;
export const SubjectDescription = styled.div`
  margin: 14px 0 20px 0;
  max-height: ${({
  expanded
}) => expanded ? '3000px' : '200px'};
  min-height: 100px;
  overflow-y: hidden;
  padding: ${({
  expanded
}) => expanded ? '0 0 50px 0' : '0'};
  position: relative;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.39, 0.78);
`;
export const Row = styled.div`
  display: flex;
  gap: 14px;
  overflow-x: auto;
  max-width: 100%;

  > * {
    flex: 0 0 auto;
    min-width: 300px;
    max-width: 500px;

    @media (max-width: 768px) {
      max-width: 200px;
    }
  }
`;
export const ShowMore = styled.div`
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(255, 255, 255, 0.9),
    white
  );
  cursor: pointer;
  padding-top: 52px;
  text-align: end;
  text-decoration: underline;
  position: absolute;
  width: 100%;
  bottom: 0;
`;