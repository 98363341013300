import styled from 'styled-components';
export const PillContainer = styled.div`
  align-items: center;
  align-self: center;
  background-color: #${({
  baseColor
}) => baseColor}55;
  border-radius: 4px;
  color: #${({
  baseColor
}) => baseColor};
  display: flex;
  font-weight: 500;
  justify-content: center;
  justify-self: center;
  padding: 4px 8px;
  text-align: center;
`;