import styled from 'styled-components';
export const Container = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;

  &:hover {
    color: rgba(0, 0, 0, 0.85);
  }

  .title {
    padding: 0 0 0 8px;
  }

  .beta {
    border: 1px solid #98a9bc;
    border-radius: 12px;
    font-size: 11px;
    letter-spacing: 0.4px;
    color: #98a9bc;
    border-color: #98a9bc;
    margin-left: 10px;
    padding: 0 6px;
  }

  .indent {
    padding-left: 30px;
  }
`;