import React from 'react';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, Input, Tooltip } from 'antd';
const COL_XS = 24;
const COL_LG = 12;
const FormInput = ({
  disabledMessage,
  handleFormState,
  isNotAutoComplete,
  label,
  name,
  placeholder,
  required,
  testid,
  type,
  value
}) => {
  return <Col xs={COL_XS} lg={COL_LG} data-testid="form-input">
      <Tooltip title={disabledMessage} placement="topLeft">
        <Form.Item label={<>
              {label}
              {required && <FontAwesomeIcon icon={faAsterisk} className="required-asterisk" />}
            </>} name={name}>
          <Input autoComplete={isNotAutoComplete && 'off'} data-lpignore={isNotAutoComplete && 'true'} onChange={input => handleFormState && handleFormState(name, input.target.value)} placeholder={placeholder ?? label} defaultValue={value} type={type} required={required} data-testid={testid ?? name} disabled={Boolean(disabledMessage)} />
        </Form.Item>
      </Tooltip>
    </Col>;
};
export default FormInput;