import React, { useEffect } from 'react';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import { getProjectDescription } from '@gtintel/platform-api';
import { DEFAULT_DATE_TIME_NOW } from '@gtintel/platform-constants';
import { useAsyncOp } from '@gtintel/platform-utils';
import { ScopeList } from '../ScopeCard';
import { SubjectList } from '../SubjectCard';
import * as S from './styles';
import FilePill from '../FilePill';
import EmptyFilePill from '../FilePill/EmptyFilePill';
const ProjectServiceDescription = ({
  projectId,
  projectName,
  projectInstructionsHtml,
  scopes,
  sampleReport,
  reportTemplate
}) => {
  const [subjects, setSubjects] = React.useState([]);
  const {
    error,
    isLoading,
    fn: getDescriptions
  } = useAsyncOp(getProjectDescription, {
    isLoading: true
  });
  useEffect(() => {
    getDescriptions({
      projectId: projectId
    }).then(res => {
      if (res && res.results.length > 0) {
        setSubjects(res.results);
      }
    });
  }, [projectId]);
  if (isLoading) {
    return <ClipLoader size={10} color="#777777" />;
  }
  if (error) {
    return <S.DescriptionContainer>
        <S.Header>Error loading project description</S.Header>
        <S.DescriptionText>
          If the problem persists, please raise an incident with the following
          information:
          <br />
          <pre>
            <code>
              Project ID: {projectId}
              <br />
              Project Name: {projectName}
              <br />
              Time: {moment().format(DEFAULT_DATE_TIME_NOW)}
            </code>
          </pre>
        </S.DescriptionText>
      </S.DescriptionContainer>;
  }
  return <S.DescriptionContainer>
      {scopes && scopes.length > 0 && <S.Title>{projectName}</S.Title>}

      {projectInstructionsHtml && projectInstructionsHtml !== '' && <S.Section>
          <S.Header>Additional Info</S.Header>
          <div dangerouslySetInnerHTML={{
        __html: projectInstructionsHtml
      }}></div>
        </S.Section>}

      {scopes && scopes.length > 0 && <S.Section>
          <S.AttachmentContainer>
            {reportTemplate ? <FilePill file={reportTemplate} linkText="Report Template" /> : <EmptyFilePill linkText="Report Template missing. Contact GTI" />}
            {sampleReport && <FilePill file={sampleReport} linkText="Example" />}
          </S.AttachmentContainer>
        </S.Section>}

      {scopes && scopes.length > 0 && subjects && subjects.length > 0 && <SubjectList subjects={subjects} />}

      {scopes && scopes.length > 0 && <ScopeList scopes={scopes} />}
    </S.DescriptionContainer>;
};
export default ProjectServiceDescription;