import { useStyletron } from 'baseui/styles';
const ResponsiveGrid = ({
  children,
  columns = 1,
  rows = 1,
  size = '1fr',
  gap = '14px'
}) => {
  const [css] = useStyletron();
  return <div className={css({
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, ${size})`,
    gridTemplateRows: `repeat(${rows}, ${size})`,
    gap: gap,
    '@media screen and (max-width: 768px)': {
      gridTemplateColumns: size
    }
  })}>
      {children}
    </div>;
};
export const TwoColumnGrid = ({
  children
}) => {
  return <ResponsiveGrid columns={2}>{children}</ResponsiveGrid>;
};
export default ResponsiveGrid;