import { Heading, HeadingLevel } from 'baseui/heading';
import moment from 'moment';
import { DEFAULT_DATE_PICKER_TIME_FORMAT, DEFAULT_TIME_FORMAT } from '@gtintel/platform-constants';
import styles from './styles.module.scss';
function formatDate(date) {
  return moment(date, DEFAULT_DATE_PICKER_TIME_FORMAT).isValid() ? moment(date, DEFAULT_DATE_PICKER_TIME_FORMAT) : moment(date);
}
const ProjectDetailsCard = ({
  id,
  name,
  site,
  created_date,
  requested_completion_date,
  requested_anonymity,
  qa_required,
  partner_price,
  client_price,
  currency_display,
  currency
}) => {
  const createdDate = formatDate(created_date);
  const requestedCompletionDate = formatDate(requested_completion_date);
  const isAdmin = site === 'admin';
  const isClient = site === 'client';
  const isPartner = site === 'partner';
  const canViewClientPrice = isAdmin || isClient;
  const canViewPartnerPrice = isAdmin || isPartner;
  const partnerPriceLabel = isAdmin ? 'Partner Proposed Pricing' : 'Your fee';
  const clientPriceLabel = isAdmin ? 'Client Price' : 'Price';
  const priceLabelPrepend = !isAdmin ? ' (Excluding VAT or other applicable taxes)' : '';
  return <section className={styles.section}>
      <HeadingLevel>
        <Heading styleLevel={6}>
          {name} <span className={styles.opacity50}>(#{id})</span>
        </Heading>
      </HeadingLevel>

      <div className={styles.details}>
        {(isAdmin || isClient) && !!created_date && <p>
            <span>Created Date: </span>
            {createdDate.isValid() ? createdDate.format(DEFAULT_TIME_FORMAT) : '-'}
          </p>}

        {!!requested_completion_date && <p>
            <span>Due Date: </span>{' '}
            {requestedCompletionDate.isValid() ? requestedCompletionDate.format(DEFAULT_TIME_FORMAT) : '-'}
          </p>}

        {isAdmin && <>
            <p>
              <span>Requested Anonymity: </span>{' '}
              {requested_anonymity ? 'Yes' : 'No'}
            </p>
            <p>
              <span>QA Required: </span>
              {qa_required ? 'Yes' : 'No'}
            </p>
          </>}

        {canViewClientPrice && <p>
            <span>{clientPriceLabel}: </span>
            {`${currency}${client_price}`}
            {priceLabelPrepend}
          </p>}

        {canViewPartnerPrice && <p>
            <span>{partnerPriceLabel}: </span>
            {`${currency}${partner_price}`}
            {priceLabelPrepend}
          </p>}
      </div>
    </section>;
};
export default ProjectDetailsCard;